.Header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
   
}


.header-menu>li>:hover{
    cursor: pointer;
    color: var(--orange);
}
.burger-menu{
height: 2rem;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
cursor: pointer;
}

.burger_class{
    width: 2rem;
    height: 0.4em;
    background-color: var(--appColor);
    border-radius: 0.5em;
}


#hu{
    position: relative;
    text-decoration: none;
    
}
#hu::after{
    position: absolute;
    content: "";
    background: var(--planCard);
    height: 3px;
    top: 1.1rem;
    width: 0;
    left: 0;
    bottom: 14px;
    transition: 0.3s;
}
#hu:hover::after{
   width: 100%;
}


@media screen and (max-width:768px){
    .Header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
        
    }
}